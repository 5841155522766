import { Box, Button, Stack, Typography } from "@mui/material";
import * as React from "react";

// icons
import Link from "../utils/link";
import Section from "../utils/section";
import theme from "../utils/theme";

interface PersonalizzaProps {
  language?: "it" | "en" | "es";
}

const Personalizza = ({ language }: PersonalizzaProps) => {
  const content = {
    it: {
      title: "Vuoi una grafica personalizzata?",
      description:
        "Realizziamo salviette e bavagli personalizzati in base alle tue esigenze. Tutti i nostri prodotti sono personalizzabili con il logo e con la tua grafica.",
      button: "Scopri la personalizzazione",
      link: "/personalizza-prodotti",
    },
    en: {
      title: "Want custom graphics?",
      description:
        "We make custom wipes and bibs to suit your needs. All our products can be customized with your logo and graphics.",
      button: "Find out about customization",
      link: "/en/customize-products",
    },
    es: {
      title: "¿Quieres un diseño personalizado?",
      description:
        "Fabricamos toallitas y baberos personalizados según tus necesidades. Todos nuestros productos son personalizables con tu logo y diseño.",
      button: "Descubre la personalización",
      link: "/es/personalizar-productos",
    },
  };

  const selectedContent = content[language || "it"];

  return (
    <Section maxWidth="md" background={theme.palette.grey[900]}>
      <Stack spacing={2} textAlign="center">
        <Typography variant="h3" component="h2">
          {selectedContent.title}
        </Typography>
        <Typography>{selectedContent.description}</Typography>

        <Box>
          <Link
            type="internal"
            href={selectedContent.link}
            title={selectedContent.button}
          >
            <Button size="large" variant="contained">
              {selectedContent.button}
            </Button>
          </Link>
        </Box>
      </Stack>
    </Section>
  );
};

export default Personalizza;
