import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Personalizza from "../components/personalizza";
import Hero from "../utils/hero";
import Link from "../utils/link";
import Page from "../utils/page";
import Section from "../utils/section";
import theme from "../utils/theme";

// markup
const BavagliPage = () => {
  return (
    <Page
      title="Bavagli monouso in tessuto non tessuto"
      description="I nostri bavaglini monouso in tnt sono un simpatico servizio aggiuntivo molto apprezzato e di rapido utilizzo per pietanze con le quali ci sia la possibilità di sporcarsi."
      type="CollectionPage"
      readMore={["/richiedi-campione-gratuito"]}
      image="/bavagli-in-tnt.jpg"
    >
      <Hero
        title="Bavagli monouso in tessuto non tessuto"
        description="Una simpatica e comoda soluzione per i tuoi clienti, dai più grandi ai più piccoli."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../images/backgrounds/fragranza-menta.jpg"
            alt="Fragranza menta"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h2">
                Formati, colori e grafiche, <br />
                scegli il bavaglio monouso in tnt adatto a te.
              </Typography>
              <Typography>
                Mettiamo a disposizione il prodotto sia per adulti che per
                bambini, da allacciare o da indossare direttamente al collo.
              </Typography>
              <Typography>
                I nostri bavaglini monouso in tnt sono un simpatico servizio
                aggiuntivo molto apprezzato e di rapido utilizzo per pietanze
                con le quali ci sia la possibilità di sporcarsi.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/products/bavaglio-nero-aragosta.jpg"
                alt="Bavaglio tnt monouso maxi nero aragosta"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Bavaglio tnt monouso maxi nero "aragosta”
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/richiedi-campione-gratuito"
                title="Scopri di più sui campioni gratuiti"
              >
                <Button variant="outlined" color="info" size="small">
                  Campione gratuito
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/products/bavaglio-bianco-papillon.jpg"
                alt="Bavaglio tnt monouso bianco papillon"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Bavaglio tnt monouso bianco "papillon”
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/richiedi-campione-gratuito"
                title="Scopri di più sui campioni gratuiti"
              >
                <Button variant="outlined" color="info" size="small">
                  Campione gratuito
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/products/bavaglio-bianco-aragosta.jpg"
                alt="Bavaglio tnt monouso bianco aragosta"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Bavaglio tnt monouso bianco "aragosta”
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/richiedi-campione-gratuito"
                title="Scopri di più sui campioni gratuiti"
              >
                <Button variant="outlined" color="info" size="small">
                  Campione gratuito
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/products/bavaglio-bianco-bambino.jpg"
                alt="Bavaglio tnt monouso bimbo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Bavaglio tnt monouso bimbo
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/richiedi-campione-gratuito"
                title="Scopri di più sui campioni gratuiti"
              >
                <Button variant="outlined" color="info" size="small">
                  Campione gratuito
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/products/bavaglio-personalizzato.jpg"
                alt="Bavaglio tnt monouso personalizzato"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Bavaglio tnt monouso personalizzato
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/richiedi-campione-gratuito"
                title="Scopri di più sui campioni gratuiti"
              >
                <Button variant="outlined" color="info" size="small">
                  Campione gratuito
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Section>

      <Personalizza />
    </Page>
  );
};

export default BavagliPage;
